<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>产品管理</el-breadcrumb-item>
      <el-breadcrumb-item>产品订单管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 筛选 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span class="title_class">订单筛选</span>
        </el-col>
      </el-row>
      <el-form
        :model="queryForm"
        class="filter_Form"
        label-width="120px"
        ref="queryFormRef"
      >
        <el-row :gutter="10">
          <el-col :span="6">
            <el-form-item label="赛季" prop="season">
              <el-select
                placeholder="请选择赛季"
                style="width: 100%"
                clearable
                v-model="queryForm.season"
              >
                <el-option
                  v-for="item in dict_season"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="6">
            <el-form-item label="学校省市区" prop="schoolArea">
              <el-cascader
                placeholder="请选择"
                clearable
                v-model="queryForm.schoolArea"
                ref="schoolAreaRef"
                :options="areasData"
                style="width: 100%"
                :props="{
                  expandTrigger: 'hover',
                  label: 'name',
                  value: 'id',
                  children: 'areas',
                }"
                @change="provincesHandleChange"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="学校" prop="schoolId">
              <el-select
                ref="schoolSelectRef"
                style="width: 100%"
                placeholder="请选择"
                v-model="queryForm.schoolId"
                clearable
                filterable
              >
                <el-option
                  v-for="item in provincesSchoolList"
                  :key="item.id"
                  :label="item.schoolName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="6">
            <el-form-item label="姓名" prop="userName">
              <el-input
                v-model="queryForm.userName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="queryForm.phone"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="6">
            <el-form-item label="订单编号" prop="orderNo">
              <el-input
                v-model="queryForm.orderNo"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="订单状态" prop="orderStatus">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.orderStatus"
                clearable
              >
                <el-option
                  v-for="item in order_status"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" justify="end">
          <el-col :span="2">
            <el-button
              type="primary"
              style="width: 100%"
              @click="getDataList('search')"
              >查询</el-button
            >
          </el-col>
          <el-col :span="2">
            <el-button type="primary" style="width: 100%" @click="reset"
              >重置</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <!-- 订单列表 -->
    <el-card class="list_card" style="margin-top: 20px">
      <el-row class="title_row">
        <el-col :span="12">
          <div class="list_title title_class">订单列表</div>
        </el-col>
        <el-col :span="12">
          <div style="text-align: right">
            <el-button type="primary" @click="exportOrderList">导出订单</el-button>
          </div>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        border
        stripe
      >
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="赛季" prop="season"></el-table-column>
        <el-table-column
          label="订单名称"
          prop="orderName"
          width="150px"
        ></el-table-column>
        <el-table-column label="订单号" prop="orderNo"></el-table-column>
        <el-table-column label="购买用户" prop="userName"></el-table-column>
        <!-- <el-table-column label="学校名称" prop="schoolName"></el-table-column>
        <el-table-column label="年级" prop="grade"></el-table-column> -->
        <el-table-column label="手机号" prop="phone"></el-table-column>
        <el-table-column label="订单金额" prop="orderAmount"></el-table-column>
        <el-table-column
          label="实付金额"
          prop="paymentAmount"
        ></el-table-column>
        <el-table-column label="下单时间" prop="orderTime"></el-table-column>
        <!-- <el-table-column label="是否寄送" prop="isSend" width="100px">
          <template slot-scope="scope">
            <el-tag
              type="success"
              v-if="scope.row.isSend + '' === '1'"
              size="mini"
              >需要寄送</el-tag
            >
            <el-tag type="warning" v-else size="mini">无需寄送</el-tag>
          </template>
        </el-table-column> -->
        <el-table-column label="操作" width="160px" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="showProductDetailDialog(scope.row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import { getTbOrderList, getSchoolListWithCity, excelTbOrderList, queryDistrictPage, getAllAreaList } from '@/http/api'
export default {
  data () {
    return {
      showSendProductDetailDialogVisible: false,
      sendProductForm: {
        expressType: '',
        expressNumber: ''
      },
      areasData: [],
      dataList: [],
      dict_express: this.$userInfo.getDataList(this, 'express_type').then(value => { this.dict_express = value }),
      dict_district: [],
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      order_source: this.$userInfo.orderSource(),
      order_status: this.$userInfo.orderStatus(),
      sendProductInfoRules: {
        expressType: [{ required: true, message: '请选择', trigger: 'change' }],
        expressNumber: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      provincesSchoolList: [],
      queryForm: {
        userName: '',
        activityType: this.$chnEngStatusCode.activityType,
        orderSource: '',
        orderStatus: '',
        productFlags: ['PRODUCT_FLAG_SPELLING_NATURALLY'],
        season: '',
        phone: '',
        pageNum: 1,
        pageSize: 10,
        orderNo: null,
        orderStartTime: '',
        orderEndTime: ''
      },
      total: 0,
      orderTimeRange: [],
      dialogVisible: false,
      uploadInfo: {
        uploadUrl: `${this.$http.defaults.baseURL}order/importSendGoodsTbOrderList`,
        headerObj: {
          Authorization: localStorage.getItem('Authorization')
        }
      },
      auditVisible: false,
      confirmVisible: false,
      refuseVisible: false,
      changeSum: false,
      refundInfo: {},
      refundApplyAmount: '', // 确认收货退款金额
      confirmReceiptGoodsReason: '' // 确认/拒绝收货原因
    }
  },
  created () {
    this.getDistrictList()
    this.getDataList()
  },
  methods: {
    getAllAreaList () {
      getAllAreaList({}).then((res) => {
        this.areasData = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 获取赛区筛选数据
    getDistrictList () {
      queryDistrictPage({ pageNum: 1, pageSize: 10000 }).then((res) => {
        this.dict_district = res.data.list
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 获取订单列表
    getDataList (getType) {
      if (getType === 'search') {
        this.queryForm.pageNum = 1
      }
      getTbOrderList(this.queryForm).then((res) => {
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 学校省市区选择
    provincesHandleChange (value) {
      console.log('value', value)
      if (!value) return
      this.provincesSchoolList = []
      this.queryForm.schoolId = ''
      this.getSchoolListData(value)
    },
    // 获取学校List
    getSchoolListData (schoolArea) {
      getSchoolListWithCity({ provinceCode: schoolArea[0], cityCode: schoolArea[1], areaCode: schoolArea[2] }).then((res) => {
        console.log('getSchoolList', res)
        this.provincesSchoolList = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 重置搜索条件
    reset () {
      this.orderTimeRange = []
      this.queryForm.orderStartTime = ''
      this.queryForm.orderEndTime = ''
      this.$refs.queryFormRef.resetFields()
      this.getDataList()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    },
    // 查看订单详情
    showProductDetailDialog (data) {
      this.$router.push(
        {
          path: '/product_Management/product_detail_management',
          query: {
            order_no: data.orderNo,
            user_id: data.userId
          }
        }
      )
    },
    // 导出订单
    exportOrderList () {
      excelTbOrderList(this.queryForm).then((res) => {
        window.location.href = res.data
      }).catch((err) => {
        console.log('err', err)
      })
    }
  }
}
</script>

<style scoped>
.a-btn{
  color: #409EFF;
  font-size: 14px;
}
.row{
  display: flex;
  margin-bottom: 12px;
}
.reason-area{
  background-color: #fafafa;
  border-radius: 12px;
  padding-left: 12px;
  padding-right: 12px;
  white-space: pre-wrap;
  /* line-height: 24px; */
}
.bb{
  border-bottom: 1px solid #eee;
  padding-bottom: 32px;
}
.price{
  color: red ;
  font-size: 18px;
}
</style>
